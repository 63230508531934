import { TabsContent } from "@/components/ui/tabs";
import { CloudIntegrationFeature } from "../CloudIntegrationFeature";
import { ImageCommentsFeature } from "../ImageCommentsFeature";
import { TaskManagementFeature } from "../TaskManagementFeature";
import { ReportsFeature } from "../ReportsFeature";
import { useSupabaseVideo } from "@/hooks/useSupabaseVideo";
import { useSupabaseImage } from "@/hooks/useSupabaseImage";

export const TabsContainer = () => {
  const { videoUrl: integrationVideoUrl, isLoading: isVideoLoading } = useSupabaseVideo('integrations.mp4', 60 * 60);
  const { isLoading: isImageLoading } = useSupabaseImage('comment_feature_full.webp', 60 * 60);

  return (
    <div className="space-y-16 sm:space-y-32">
      <TabsContent value="integrations">
        <CloudIntegrationFeature 
          videoUrl={integrationVideoUrl} 
          isLoading={isVideoLoading} 
        />
      </TabsContent>
      
      <TabsContent value="comments">
        <ImageCommentsFeature isLoading={isImageLoading} />
      </TabsContent>
      
      <TabsContent value="tasks">
        <TaskManagementFeature />
      </TabsContent>
      
      <TabsContent value="reports">
        <ReportsFeature />
      </TabsContent>
    </div>
  );
};