import Navigation from "../components/Navigation";
import { HeroSection } from "../components/sections/HeroSection";
import { CameraRollSection } from "../components/sections/CameraRollSection";
import { QRCodeSection } from "../components/sections/QRCodeSection";
import { HowItWorksSection } from "../components/sections/HowItWorksSection";
import { FeaturesSection } from "../components/sections/FeaturesSection";
import { FaqSection } from "../components/sections/FaqSection";
import { Footer } from "../components/sections/Footer";

const Index = () => {
  return (
    <div className="min-h-screen">
      <Navigation />
      <HeroSection />
      <CameraRollSection />
      <QRCodeSection />
      <HowItWorksSection />
      <FeaturesSection />
      <FaqSection />
      <Footer />
    </div>
  );
};

export default Index;