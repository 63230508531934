import { useState, useEffect } from "react";

// Define breakpoints matching Tailwind's default breakpoints
const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export type Breakpoint = keyof typeof breakpoints;

export const useResponsive = () => {
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const [breakpoint, setBreakpoint] = useState<Breakpoint>('sm');
  
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      
      // Determine current breakpoint
      if (width >= breakpoints['2xl']) {
        setBreakpoint('2xl');
      } else if (width >= breakpoints.xl) {
        setBreakpoint('xl');
      } else if (width >= breakpoints.lg) {
        setBreakpoint('lg');
      } else if (width >= breakpoints.md) {
        setBreakpoint('md');
      } else {
        setBreakpoint('sm');
      }

      console.log(`[useResponsive] Window resized - Width: ${width}px, Breakpoint: ${breakpoint}`);
    };

    // Initial check
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  const isBreakpoint = (bp: Breakpoint) => windowWidth >= breakpoints[bp];

  return {
    windowWidth,
    breakpoint,
    isMobile: !isBreakpoint('md'),
    isTablet: isBreakpoint('md') && !isBreakpoint('lg'),
    isDesktop: isBreakpoint('lg'),
    isBreakpoint,
    breakpoints,
  };
};