import { useState } from "react";
import { Button } from "./ui/button";
import { Dialog, DialogTrigger } from "./ui/dialog";
import { useFormSubmission } from "@/hooks/useFormSubmission";
import { DemoRequestDialog } from "./demo-request/DemoRequestDialog";
import { useResponsive } from "@/hooks/useResponsive";
import { supabase } from "@/integrations/supabase/client"; // Add this import

interface DemoRequestData {
  name: string;
  email: string;
  phone_number?: string;
  company?: string;
}

export const DemoRequestForm = () => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useResponsive();
  const { isLoading, handleSubmit } = useFormSubmission<DemoRequestData>({
    onSubmit: async (data) => {
      console.log("Submitting demo request:", data);
      const { error } = await supabase.from("demo_requests").insert([data]);
      return { success: !error, error };
    },
    successMessage: "Your demo request has been submitted. We'll be in touch soon!",
    errorMessage: "There was a problem submitting your request. Please try again.",
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get("name") as string,
      email: formData.get("email") as string,
      phone_number: formData.get("phone") as string,
      company: formData.get("company") as string,
    };

    const success = await handleSubmit(data);
    if (success) {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button 
          size="lg" 
          className={`w-full sm:w-auto px-8 py-3 rounded-xl bg-white text-primary border-2 border-primary hover:bg-primary hover:text-white transition-all duration-300 flex items-center justify-center min-w-[160px] ${
            isMobile ? 'text-base' : 'text-sm'
          }`}
        >
          Request Demo
        </Button>
      </DialogTrigger>
      <DemoRequestDialog isLoading={isLoading} onSubmit={onSubmit} />
    </Dialog>
  );
};