import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { VideoPlayer } from "../ui/VideoPlayer";

// Cache for storing the signed URL
let cachedUrl: { url: string; expiry: number } | null = null;

export const QRCodeSection = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchVideoUrl = async () => {
    try {
      // Check if we have a valid cached URL
      if (cachedUrl && cachedUrl.expiry > Date.now()) {
        console.log("Using cached QR code video URL");
        setVideoUrl(cachedUrl.url);
        setIsLoading(false);
        return;
      }

      console.log("Fetching new QR code video URL...");
      const { data: publicUrlData, error } = await supabase.storage
        .from('media')
        .createSignedUrl('qr_code_vid.mp4', 60 * 60);

      if (error) {
        console.error("Error getting signed URL:", error);
        setIsLoading(false);
        return;
      }

      if (publicUrlData?.signedUrl) {
        // Cache the URL with expiry time
        cachedUrl = {
          url: publicUrlData.signedUrl,
          expiry: Date.now() + (60 * 60 * 1000) // 1 hour from now
        };
        console.log("Setting new QR code video URL");
        setVideoUrl(publicUrlData.signedUrl);
      }
    } catch (err) {
      console.error("Failed to fetch video URL:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  return (
    <section className="pt-4 md:pt-6 pb-16 md:pb-24 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-6">
            Instant Site Updates with QR Codes
          </h2>
          <p className="text-lg text-gray-600">
            Print QR codes for your projects, place them on site, and let anyone upload images instantly - no login required.
          </p>
        </div>
        {isLoading ? (
          <div className="w-full max-w-[800px] mx-auto aspect-video bg-gray-50 animate-pulse flex items-center justify-center">
            <span className="text-gray-400">Loading...</span>
          </div>
        ) : videoUrl ? (
          <div className="w-full max-w-[800px] mx-auto">
            <VideoPlayer
              src={videoUrl}
              alt="QR code feature demonstration"
              className="w-full"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        ) : (
          <div className="w-full max-w-[800px] mx-auto aspect-video bg-gray-50 flex items-center justify-center">
            <span className="text-gray-400">Failed to load video</span>
          </div>
        )}
      </div>
    </section>
  );
};