import { VideoPlayer } from "@/components/ui/VideoPlayer";

interface CloudIntegrationFeatureProps {
  videoUrl: string;
  isLoading: boolean;
}

export const CloudIntegrationFeature = ({ videoUrl, isLoading }: CloudIntegrationFeatureProps) => {
  return (
    <div 
      id="cloud-integration" 
      className="flex flex-col md:flex-row items-center justify-between gap-8 sm:gap-16 scroll-mt-24 sm:scroll-mt-32"
    >
      <div className="w-full md:w-1/2 px-4 sm:px-0">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center md:text-left">
          Simplify Image Storage with Seamless Cloud Integration
        </h2>
        <p className="text-sm sm:text-base text-gray-600 text-center md:text-left mb-3 sm:mb-4">
          Sync your construction site photos directly from Google Drive, Dropbox, or even SMS without changing your workflow. Upload images as you normally do, and Property Vue does the rest.
        </p>
        <p className="text-sm sm:text-base text-gray-600 text-center md:text-left">
          By syncing with your existing tools, Property Vue turns your photos into actionable assets—ready for organization, task creation, and reports—saving you time and effort.
        </p>
      </div>
      <div className="w-full md:w-1/2 flex justify-center">
        <div className="w-full max-w-2xl md:w-[80%]">
          {isLoading ? (
            <div className="w-full aspect-video bg-gray-50 animate-pulse rounded-lg flex items-center justify-center">
              <span className="text-gray-400">Loading...</span>
            </div>
          ) : (
            <VideoPlayer
              src={videoUrl}
              alt="Cloud integration demonstration"
              className="w-full aspect-video"
            />
          )}
        </div>
      </div>
    </div>
  );
};