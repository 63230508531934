import { useState, useCallback } from "react";
import { useToast } from "@/components/ui/use-toast";

interface FormSubmissionConfig<T> {
  onSubmit: (data: T) => Promise<{ success: boolean; error?: any }>;
  successMessage?: string;
  errorMessage?: string;
}

export function useFormSubmission<T>({
  onSubmit,
  successMessage = "Submission successful!",
  errorMessage = "There was a problem with your submission. Please try again.",
}: FormSubmissionConfig<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = useCallback(async (data: T): Promise<boolean> => {
    console.log("Form submission started with data:", data);
    setIsLoading(true);

    try {
      const { success, error } = await onSubmit(data);
      
      if (success) {
        console.log("Form submission successful");
        toast({
          title: "Success!",
          description: successMessage,
        });
        return true;
      } else {
        throw error;
      }
    } catch (error) {
      console.error("Form submission error:", error);
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [onSubmit, successMessage, errorMessage, toast]);

  return {
    isLoading,
    handleSubmit,
  };
}