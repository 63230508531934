import { motion } from "framer-motion";
import { CommentPin } from "../integration/comments/CommentPin";
import { useSupabaseImage } from "@/hooks/useSupabaseImage";
import { Skeleton } from "@/components/ui/skeleton";

const comments = [
  {
    id: 1,
    x: 20,
    y: 25,
    text: "Pin comments anywhere on images",
  },
  {
    id: 2,
    x: 75,
    y: 30,
    text: "Add detailed text for clarity",
  },
  {
    id: 3,
    x: 30,
    y: 70,
    text: "See exactly who commented where",
  },
  {
    id: 4,
    x: 80,
    y: 65,
    text: "Comments stay linked to tasks",
  },
];

interface CommentImageProps {
  isLoading: boolean;
}

export const CommentImage = ({ isLoading: parentLoading }: CommentImageProps) => {
  const { imageUrl: fullImageUrl, isLoading: isFullLoading } = useSupabaseImage('comment_feature_full.webp');
  const { imageUrl: mediumImageUrl, isLoading: isMediumLoading } = useSupabaseImage('comment_feature_medium.webp');
  const { imageUrl: smallImageUrl, isLoading: isSmallLoading } = useSupabaseImage('comment_feature_small.webp');

  console.log('Comment feature URLs:', { fullImageUrl, mediumImageUrl, smallImageUrl });

  const isLoading = parentLoading || isFullLoading || isMediumLoading || isSmallLoading;

  if (isLoading) {
    return <Skeleton className="w-[90%] md:w-[60%] mx-auto aspect-video rounded-lg" />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className="relative w-[90%] md:w-[60%] mx-auto rounded-lg overflow-hidden"
    >
      <picture>
        <source
          media="(min-width: 1024px)"
          srcSet={fullImageUrl}
          type="image/webp"
        />
        <source
          media="(min-width: 640px)"
          srcSet={mediumImageUrl}
          type="image/webp"
        />
        <img
          src={smallImageUrl}
          alt="Construction site with interactive comments"
          className="w-full h-auto"
          loading="lazy"
          decoding="async"
          onError={(e) => {
            console.error('Error loading comment feature image');
            e.currentTarget.src = "/placeholder.svg";
          }}
        />
      </picture>
      {comments.map((comment) => (
        <CommentPin key={comment.id} {...comment} />
      ))}
    </motion.div>
  );
};