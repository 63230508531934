import { FC } from 'react';

interface TaskHeaderProps {
  title: string;
  description: string;
  projectName: string;
}

export const TaskHeader: FC<TaskHeaderProps> = ({ title, description, projectName }) => {
  console.log('Rendering TaskHeader with title:', title);
  
  return (
    <div className="flex-1 space-y-2 sm:space-y-3 w-full sm:w-auto">
      <h3 className="text-lg sm:text-xl font-semibold text-gray-900">{title}</h3>
      <p className="text-sm sm:text-base text-gray-600 max-w-xl">{description}</p>
      <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs sm:text-sm bg-primary/10 text-primary">
        {projectName}
      </span>
    </div>
  );
};