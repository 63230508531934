import { MockReport } from "./report/MockReport";

export const ReportsFeature = () => {
  return (
    <div 
      id="generate-reports" 
      className="flex flex-col md:flex-row items-center justify-between gap-8 sm:gap-16 scroll-mt-24 sm:scroll-mt-32"
    >
      <div className="w-full md:w-1/2">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-left">
          Impress Stakeholders with Professional Image-Based Reports
        </h2>
        <p className="text-sm sm:text-base text-gray-600 text-left mb-3 sm:mb-4">
          Quickly generate professional reports using your construction site photos to update clients, track progress, or meet inspection requirements.
        </p>
        <p className="text-sm sm:text-base text-gray-600 text-left">
          No more struggling to compile reports manually. Property Vue makes it easy to create polished PDFs that deliver the right information in a consistent, digestible format—saving time and making a great impression.
        </p>
      </div>
      <MockReport />
    </div>
  );
};