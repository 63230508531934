import { Input } from "../ui/input";

interface DemoRequestFieldsProps {
  isLoading: boolean;
}

export const DemoRequestFields = ({ isLoading }: DemoRequestFieldsProps) => {
  return (
    <div className="space-y-4">
      <div>
        <Input
          name="name"
          placeholder="Your Name"
          required
          className="w-full bg-white"
          disabled={isLoading}
        />
      </div>
      <div>
        <Input
          name="email"
          type="email"
          placeholder="Email Address"
          required
          className="w-full bg-white"
          disabled={isLoading}
        />
      </div>
      <div>
        <Input
          name="phone"
          type="tel"
          placeholder="Phone Number (Optional)"
          className="w-full bg-white"
          disabled={isLoading}
        />
      </div>
      <div>
        <Input
          name="company"
          placeholder="Company Name (Optional)"
          className="w-full bg-white"
          disabled={isLoading}
        />
      </div>
    </div>
  );
};