import { motion } from "framer-motion";
import { useState } from "react";
import confetti from "canvas-confetti";
import { TaskHeader } from "./task/TaskHeader";
import { TaskImage } from "./task/TaskImage";
import { TaskMetadata } from "./task/TaskMetadata";
import { CompleteButton } from "./task/CompleteButton";

export const TaskDemo = () => {
  const [isComplete, setIsComplete] = useState(false);
  const dueDate = new Date(1776, 6, 4);

  const handleComplete = () => {
    console.log('Handling task completion');
    setIsComplete(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.8 }
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className="relative bg-white rounded-xl shadow-lg p-3 sm:p-6 w-full max-w-2xl mx-auto transform scale-[0.85] sm:scale-90 md:scale-100"
    >
      <div className="flex flex-col space-y-4 sm:space-y-6">
        <div className="flex flex-col sm:flex-row items-start justify-between gap-4 sm:gap-6">
          <TaskHeader 
            title="Utility Pipe Inspection"
            description="Inspect the cleared path and verify trench and pipe locations"
            projectName="Palm Grove Renovation"
          />
          <TaskImage imagePath="taskImage1.png" />
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0 pt-4 border-t border-gray-100">
          <TaskMetadata dueDate={dueDate} />
          <CompleteButton 
            isComplete={isComplete}
            onComplete={handleComplete}
          />
        </div>
      </div>
    </motion.div>
  );
};