import { TabsList, TabsTrigger } from "@/components/ui/tabs";

interface FeaturesTabsProps {
  onTabChange: (section: string) => void;
}

interface TabItem {
  value: string;
  label: string;
  sectionId: string;
}

const tabs: TabItem[] = [
  { value: "integrations", label: "Cloud Integration", sectionId: "cloud-integration" },
  { value: "comments", label: "Image Comments", sectionId: "image-comments" },
  { value: "tasks", label: "Manage Tasks", sectionId: "manage-tasks" },
  { value: "reports", label: "Generate Reports", sectionId: "generate-reports" },
];

export const FeaturesTabs = ({ onTabChange }: FeaturesTabsProps) => {
  return (
    <div className="mb-8 sm:mb-16 bg-white overflow-x-hidden">
      <TabsList className="h-auto grid w-full max-w-3xl grid-cols-2 sm:grid-cols-4 mx-auto bg-primary/[0.03] rounded-full p-1.5 gap-1.5">
        {tabs.map(({ value, label, sectionId }) => (
          <TabsTrigger 
            key={value}
            value={value} 
            onClick={() => onTabChange(sectionId)}
            className="min-h-[3rem] sm:h-12 rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200 text-xs sm:text-base whitespace-normal text-center px-3 sm:px-4 py-2 touch-manipulation"
          >
            {label}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );
};