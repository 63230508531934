import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Twitter } from "lucide-react";

export const Footer = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-primary/40 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="py-12 grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-12">
          {/* Logo and Description Column */}
          <div className="md:col-span-5 space-y-4">
            <img
              src="/lovable-uploads/6b2989bd-450b-4039-994f-736a4d8cf30c.png"
              alt="Property Vue Logo"
              className="h-12 w-auto"
            />
            <p className="text-sm text-white/80 mt-4 max-w-md">
              Streamlining your construction workflows with smarter image management.
            </p>
          </div>

          {/* Company Links */}
          <div className="md:col-span-3">
            <h3 className="font-bold text-sm mb-4 md:mb-6">COMPANY</h3>
            <ul className="space-y-3 md:space-y-4">
              <li>
                <button 
                  onClick={() => scrollToSection("features")} 
                  className="text-sm text-white/80 hover:text-white transition-colors"
                >
                  Features
                </button>
              </li>
              <li>
                <button 
                  onClick={() => scrollToSection("faq")} 
                  className="text-sm text-white/80 hover:text-white transition-colors"
                >
                  FAQ
                </button>
              </li>
              <li>
                <Link to="/pricing" className="text-sm text-white/80 hover:text-white transition-colors">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-sm text-white/80 hover:text-white transition-colors">
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Links */}
          <div className="md:col-span-3">
            <h3 className="font-bold text-sm mb-4 md:mb-6">RESOURCES</h3>
            <ul className="space-y-3 md:space-y-4">
              <li>
                <Link to="/info#contact" className="text-sm text-white/80 hover:text-white transition-colors">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/info#terms" className="text-sm text-white/80 hover:text-white transition-colors">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/info#privacy" className="text-sm text-white/80 hover:text-white transition-colors">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-white/10 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-sm text-white/80 text-center md:text-left">© 2024 Property Vue. All rights reserved.</p>
          
          {/* Social Links */}
          <div className="flex items-center gap-6">
            <a 
              href="https://www.linkedin.com/company/propertyvue/?viewAsMember=true" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white/80 hover:text-white transition-colors p-2"
            >
              <Linkedin className="h-5 w-5" />
            </a>
            <a 
              href="https://www.instagram.com/propertyvue.io/?next=%2F" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white/80 hover:text-white transition-colors p-2"
            >
              <Instagram className="h-5 w-5" />
            </a>
            <a 
              href="https://www.facebook.com/profile.php?id=100089426465636" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white/80 hover:text-white transition-colors p-2"
            >
              <Facebook className="h-5 w-5" />
            </a>
            <a 
              href="https://x.com/propertyvueapp" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white/80 hover:text-white transition-colors p-2"
            >
              <Twitter className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};