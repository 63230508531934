import { FC } from 'react';

interface CompleteButtonProps {
  isComplete: boolean;
  onComplete: () => void;
}

export const CompleteButton: FC<CompleteButtonProps> = ({ isComplete, onComplete }) => {
  console.log('Rendering CompleteButton, complete status:', isComplete);
  
  return (
    <div className="relative w-full sm:w-auto">
      <button
        onClick={onComplete}
        disabled={isComplete}
        className={`relative z-10 w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-2.5 rounded-full text-sm transition-colors ${
          isComplete 
            ? "bg-green-100 text-green-800"
            : "bg-gray-100 text-gray-700 hover:bg-green-50 hover:text-green-700"
        }`}
      >
        {isComplete ? "Completed" : "Mark Complete"}
      </button>
      {!isComplete && (
        <div className="absolute -inset-4 z-0">
          <div className="absolute inset-0 animate-pulse-scale rounded-full bg-green-600/30" />
          <div className="absolute inset-0 animate-pulse-scale delay-150 rounded-full bg-green-600/20" />
        </div>
      )}
    </div>
  );
};