import { FC } from 'react';
import { format } from 'date-fns';

interface TaskMetadataProps {
  dueDate: Date;
}

export const TaskMetadata: FC<TaskMetadataProps> = ({ dueDate }) => {
  console.log('Rendering TaskMetadata for date:', dueDate);
  
  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm sm:text-base text-red-600 font-medium">
        Due: {format(dueDate, 'MMMM d, yyyy')}
      </span>
      <span className="px-2 py-0.5 text-xs font-medium bg-red-50 text-red-600 rounded-full">
        Overdue
      </span>
    </div>
  );
};