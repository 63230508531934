import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface ReportImages {
  full: string;
  medium: string;
  small: string;
}

const fetchReportImages = async (): Promise<ReportImages> => {
  console.log('Fetching report image URLs');
  
  const [fullRes, mediumRes, smallRes] = await Promise.all([
    supabase.storage
      .from('media')
      .createSignedUrl('fake_report_full.webp', 60 * 60),
    supabase.storage
      .from('media')
      .createSignedUrl('fake_report_medium.webp', 60 * 60),
    supabase.storage
      .from('media')
      .createSignedUrl('fake_report_small.webp', 60 * 60)
  ]);

  if (fullRes.error || mediumRes.error || smallRes.error) {
    console.error('Error fetching report images:', { fullRes, mediumRes, smallRes });
    throw new Error('Failed to fetch report images');
  }

  return {
    full: fullRes.data?.signedUrl || '',
    medium: mediumRes.data?.signedUrl || '',
    small: smallRes.data?.signedUrl || '',
  };
};

export const useReportImages = () => {
  return useQuery({
    queryKey: ['reportImages'],
    queryFn: fetchReportImages,
    staleTime: 55 * 60 * 1000, // Consider data fresh for 55 minutes (5 minutes before URL expiration)
    gcTime: 60 * 60 * 1000, // Cache for 1 hour
  });
};