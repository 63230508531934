import { HeroContent } from "./hero/HeroContent";
import { useSupabaseVideo } from "@/hooks/useSupabaseVideo";
import { VideoPlayer } from "../ui/VideoPlayer";

export const HeroSection = () => {
  const { videoUrl, isLoading } = useSupabaseVideo('hero_video.mp4', 60 * 60 * 24);

  return (
    <section className="pt-24 md:pt-32 pb-8 md:pb-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
          <HeroContent />
          <div className="flex justify-center md:justify-end mt-6 md:mt-0">
            {isLoading ? (
              <div className="w-full aspect-video bg-gray-50 animate-pulse flex items-center justify-center">
                <span className="text-gray-400">Loading...</span>
              </div>
            ) : videoUrl ? (
              <div className="w-full max-w-[966px]">
                <VideoPlayer
                  src={videoUrl}
                  alt="Hero animation"
                  className="w-full"
                />
              </div>
            ) : (
              <div className="w-full aspect-video bg-gray-50 flex items-center justify-center">
                <span className="text-gray-400">Failed to load video</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};