import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../components/Navigation";
import { Footer } from "../components/sections/Footer";
import { InfoSideNav } from "../components/info/InfoSideNav";
import { ContactSection } from "../components/info/ContactSection";
import { TermsSection } from "../components/info/TermsSection";
import { PrivacySection } from "../components/info/PrivacySection";

const Info = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="min-h-screen">
      <Navigation />
      
      <div className="max-w-7xl mx-auto px-4 page-padding">
        <div className="relative flex">
          <InfoSideNav />
          
          <div className="flex-1 lg:ml-44">
            <ContactSection />
            <TermsSection />
            <PrivacySection />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Info;