import { CommentImage } from "./CommentImage";

interface ImageCommentsFeatureProps {
  isLoading: boolean;
}

export const ImageCommentsFeature = ({ isLoading }: ImageCommentsFeatureProps) => {
  return (
    <div 
      id="image-comments" 
      className="flex flex-col md:flex-row-reverse items-center justify-between gap-8 sm:gap-16 scroll-mt-24 sm:scroll-mt-32"
    >
      <div className="w-full md:w-1/2 px-4 sm:px-0">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center md:text-left">
          Collaborate Effectively with Contextual Image Comments
        </h2>
        <p className="text-sm sm:text-base text-gray-600 text-center md:text-left mb-3 sm:mb-4">
          Keep your team aligned by leaving and viewing comments directly on your site photos. Digital annotations ensure everyone has the context they need.
        </p>
        <p className="text-sm sm:text-base text-gray-600 text-center md:text-left">
          Easy-to-use numbered pins make feedback clear and accessible, helping your team tackle issues and stay aligned without unnecessary back-and-forth communication.
        </p>
      </div>
      <div className="w-full md:w-1/2 flex justify-center">
        <CommentImage isLoading={isLoading} />
      </div>
    </div>
  );
};