import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface PulsingDotProps {
  position: string;
  content: string;
}

export const PulsingDot = ({ position, content }: PulsingDotProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className={`absolute ${position} cursor-pointer touch-manipulation`}>
          <div className="relative">
            <div className="absolute -inset-4 rounded-full animate-pulse-ring bg-gradient-to-r from-blue-500/50 to-white/30" />
            <div className="absolute -inset-4 rounded-full animate-pulse-ring delay-1000 bg-gradient-to-r from-blue-500/40 to-white/20" />
            <div className="relative bg-blue-500 rounded-full p-1.5 flex items-center justify-center w-3 h-3" />
          </div>
        </button>
      </PopoverTrigger>
      <PopoverContent 
        side="right" 
        align="center"
        sideOffset={5}
        className="min-w-[200px] max-w-[300px] bg-white text-gray-800 border-0 shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-4 z-50"
        avoidCollisions={true}
        collisionPadding={16}
        sticky="always"
      >
        <p className="text-sm leading-relaxed">{content}</p>
      </PopoverContent>
    </Popover>
  );
};