import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useFormSubmission } from "@/hooks/useFormSubmission";
import { useResponsive } from "@/hooks/useResponsive";
import { supabase } from "@/integrations/supabase/client";

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const ContactForm = () => {
  const { isMobile } = useResponsive();
  const { isLoading, handleSubmit } = useFormSubmission<ContactFormData>({
    onSubmit: async (data) => {
      console.log("Submitting contact form:", data);
      const { error } = await supabase.from("support_requests").insert([data]);
      return { success: !error, error };
    },
    successMessage: "Your message has been sent. We'll respond within 24 hours.",
    errorMessage: "There was a problem sending your message. Please try again.",
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get("name") as string,
      email: formData.get("email") as string,
      message: formData.get("message") as string,
    };

    const success = await handleSubmit(data);
    if (success) {
      e.currentTarget.reset();
    }
  };

  return (
    <form onSubmit={onSubmit} className="space-y-4 max-w-xl mx-auto">
      <div>
        <Input
          name="name"
          placeholder="Your Name"
          required
          className={`w-full ${isMobile ? 'text-base' : 'text-sm'}`}
        />
      </div>
      <div>
        <Input
          name="email"
          type="email"
          placeholder="Email Address"
          required
          className={`w-full ${isMobile ? 'text-base' : 'text-sm'}`}
        />
      </div>
      <div>
        <Textarea
          name="message"
          placeholder="How can we help?"
          required
          className={`w-full min-h-[120px] ${isMobile ? 'text-base' : 'text-sm'}`}
        />
      </div>
      <Button
        type="submit"
        className="w-full bg-primary text-white hover:bg-primary/90"
        disabled={isLoading}
      >
        {isLoading ? "Sending..." : "Send Message"}
      </Button>
    </form>
  );
};