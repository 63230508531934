import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { StepCard } from "./howItWorks/StepCard";
import { StepHeader } from "./howItWorks/StepHeader";

const steps = [
  {
    number: "01",
    title: "Create a Workspace",
    description: "Set up your workspace to organize all your images and collaborate with your team.",
  },
  {
    number: "02",
    title: "Create a Project",
    description: "Create projects for each jobsite or property to keep your images organized and accessible.",
  },
  {
    number: "03",
    title: "Start Adding Images",
    description: "Upload and organize your images by project and tags. Create Tasks, Reports, and start collaborating with your team.",
  },
];

export const HowItWorksSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="relative -mt-12 sm:-mt-16 md:-mt-24 pt-20 sm:pt-24 md:pt-32 pb-24 sm:pb-32 md:pb-40 overflow-hidden">
      {/* Enhanced background with multiple layers for better section blending */}
      <div className="absolute inset-0">
        {/* Top fade gradient */}
        <div className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-white via-white/90 to-transparent" />
        
        {/* Base gradient with increased opacity for better blending */}
        <div className="absolute inset-0 bg-gradient-to-br from-primary/[0.04] via-white to-primary/[0.03]" />
        
        {/* Radial gradient overlays for depth */}
        <div className="absolute inset-0">
          <div className="absolute top-1/4 left-0 w-1/2 h-1/2 bg-gradient-radial from-primary/[0.08] to-transparent opacity-75" />
          <div className="absolute bottom-1/4 right-0 w-1/2 h-1/2 bg-gradient-radial from-accent/[0.1] to-transparent opacity-60" />
        </div>

        {/* Subtle animated gradient overlay */}
        <div 
          className="absolute inset-0 opacity-20"
          style={{
            background: 'linear-gradient(45deg, transparent, rgba(13, 51, 102, 0.05), transparent)',
            backgroundSize: '200% 200%',
            animation: 'gradient-shift 8s ease infinite'
          }}
        />

        {/* Bottom fade gradient */}
        <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-white via-white/90 to-transparent" />
      </div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
          className="space-y-8 sm:space-y-12 md:space-y-16"
        >
          <StepHeader />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            {steps.map((step, index) => (
              <StepCard
                key={step.number}
                step={step}
                index={index}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};