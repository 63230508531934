import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";
import { DemoRequestFields } from "./DemoRequestFields";

interface DemoRequestDialogProps {
  isLoading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

export const DemoRequestDialog = ({ isLoading, onSubmit }: DemoRequestDialogProps) => {
  return (
    <DialogContent className="sm:max-w-[425px] bg-white border-none">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold text-primary">Request a Demo</DialogTitle>
        <DialogDescription className="text-gray-600">
          Fill out this form and we'll get back to you within 24 hours.
        </DialogDescription>
      </DialogHeader>
      <form onSubmit={onSubmit} className="space-y-4 mt-4">
        <DemoRequestFields isLoading={isLoading} />
        <Button
          type="submit"
          className="w-full bg-primary text-white hover:bg-primary/90"
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit Request"}
        </Button>
      </form>
    </DialogContent>
  );
};