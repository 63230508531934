import { useSupabaseImage } from "@/hooks/useSupabaseImage";
import { useResponsive } from "@/hooks/useResponsive";

export const CameraRollSection = () => {
  const { isBreakpoint } = useResponsive();
  const { imageUrl: fullImageUrl, isLoading: isFullLoading } = useSupabaseImage('camera_roll_full.webp');
  const { imageUrl: mediumImageUrl, isLoading: isMediumLoading } = useSupabaseImage('camera_roll_medium.webp');
  const { imageUrl: smallImageUrl, isLoading: isSmallLoading } = useSupabaseImage('camera_roll_small.webp');

  const isLoading = isFullLoading || isMediumLoading || isSmallLoading;

  return (
    <section className="pt-4 md:pt-6 pb-16 md:pb-24 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="w-full max-w-[800px] mx-auto">
          {isLoading ? (
            <div className="w-full aspect-video bg-gray-100 animate-pulse" />
          ) : (
            <picture className="w-full block">
              {/* Small screens */}
              <source
                media="(max-width: 640px)"
                srcSet={smallImageUrl}
                type="image/webp"
              />
              {/* Medium screens */}
              <source
                media="(max-width: 1024px)"
                srcSet={mediumImageUrl}
                type="image/webp"
              />
              {/* Large screens */}
              <source
                srcSet={fullImageUrl}
                type="image/webp"
              />
              
              {/* Fallback image */}
              <img
                src={fullImageUrl}
                alt="Camera roll demonstration"
                className="w-full h-auto"
                loading="eager"
              />
            </picture>
          )}
        </div>
      </div>
    </section>
  );
};