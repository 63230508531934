import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Menu } from "lucide-react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

const navItems = [
  { id: "contact", label: "Contact Us" },
  { id: "terms", label: "Terms of Service" },
  { id: "privacy", label: "Privacy Policy" },
];

export const InfoSideNav = () => {
  const [activeSection, setActiveSection] = useState<string>("contact");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: "-20% 0px -80% 0px",
      }
    );

    const sections = navItems.map((item) => 
      document.getElementById(item.id)
    ).filter(Boolean);

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => sections.forEach((section) => {
      if (section) observer.unobserve(section);
    });
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const NavLinks = () => (
    <>
      {navItems.map((item) => (
        <button
          key={item.id}
          onClick={() => scrollToSection(item.id)}
          className={cn(
            "w-full text-left px-3 py-2 rounded-lg transition-colors duration-200 text-sm",
            "hover:bg-primary/5",
            activeSection === item.id
              ? "text-primary font-medium bg-primary/5"
              : "text-primary/70"
          )}
        >
          {item.label}
        </button>
      ))}
    </>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="hidden lg:block fixed left-6 top-32 space-y-1 w-40">
        <NavLinks />
      </nav>

      {/* Mobile Navigation */}
      <div className="lg:hidden fixed bottom-6 right-6 z-50">
        <Sheet>
          <SheetTrigger className="bg-primary text-white p-3 rounded-full shadow-lg">
            <Menu size={24} />
          </SheetTrigger>
          <SheetContent side="bottom" className="h-[40vh]">
            <nav className="flex flex-col space-y-1 mt-6">
              <NavLinks />
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};