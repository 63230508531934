import { Accordion } from "@/components/ui/accordion";
import { motion } from "framer-motion";
import { FaqHeader } from "./faq/FaqHeader";
import { FaqItem } from "./faq/FaqItem";
import { FaqFooter } from "./faq/FaqFooter";
import { faqs } from "./faq/faqData";

export const FaqSection = () => {
  return (
    <section id="faq" className="py-16 md:py-20 px-4 md:px-6 lg:px-8 bg-gradient-to-b from-accent/5 to-white">
      <div className="max-w-3xl mx-auto">
        <FaqHeader />
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="space-y-6"
        >
          <Accordion type="single" collapsible className="space-y-6">
            {faqs.map((faq, index) => (
              <FaqItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                index={index}
              />
            ))}
          </Accordion>
        </motion.div>

        <FaqFooter />
      </div>
    </section>
  );
};