import { motion } from "framer-motion";
import { Skeleton } from "@/components/ui/skeleton";
import { useSupabaseImage } from "@/hooks/useSupabaseImage";

interface ReportImageProps {
  isLoading: boolean;
  className?: string;
}

export const ReportImage = ({ isLoading: parentLoading, className = "" }: ReportImageProps) => {
  const { imageUrl: fullImageUrl, isLoading: isFullLoading } = useSupabaseImage('fake_report_full.webp');
  const { imageUrl: mediumImageUrl, isLoading: isMediumLoading } = useSupabaseImage('fake_report_medium.webp');
  const { imageUrl: smallImageUrl, isLoading: isSmallLoading } = useSupabaseImage('fake_report_small.webp');

  console.log('Report image URLs:', { fullImageUrl, mediumImageUrl, smallImageUrl });

  const isLoading = parentLoading || isFullLoading || isMediumLoading || isSmallLoading;

  if (isLoading) {
    return <Skeleton className="w-full max-w-[350px] h-[450px] mx-auto rounded-lg" />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      className={`w-full max-w-[350px] mx-auto px-4 sm:px-0 ${className}`}
    >
      <picture>
        <source
          media="(min-width: 1024px)"
          srcSet={fullImageUrl}
          type="image/webp"
        />
        <source
          media="(min-width: 640px)"
          srcSet={mediumImageUrl}
          type="image/webp"
        />
        <img
          src={smallImageUrl}
          alt="Sample Report"
          className="w-full h-auto rounded-lg shadow-md border border-gray-100"
          loading="lazy"
          decoding="async"
          onError={(e) => {
            console.error('Error loading report image');
            e.currentTarget.src = "/placeholder.svg";
          }}
        />
      </picture>
    </motion.div>
  );
};