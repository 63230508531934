import { useReportImages } from "@/hooks/useReportImages";
import { PulsingDot } from "./PulsingDot";
import { ReportImage } from "./ReportImage";

export const MockReport = () => {
  const { isLoading } = useReportImages();
  
  return (
    <div className="relative w-full md:w-1/2 touch-none">
      <ReportImage isLoading={isLoading} />
      
      {!isLoading && (
        <>
          <PulsingDot 
            position="top-[15%] left-[20%]"
            content="Customize reports with your brand's logo and colors"
          />

          <PulsingDot 
            position="top-[45%] left-[25%]"
            content="Add detailed summaries and notes to each section of your report"
          />

          <PulsingDot 
            position="bottom-[25%] left-[20%]"
            content="Easily integrate images from any of your projects to share"
          />
        </>
      )}
    </div>
  );
};