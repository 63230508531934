import { FC } from 'react';
import { useSupabaseImage } from "@/hooks/useSupabaseImage";
import { Skeleton } from "@/components/ui/skeleton";

interface TaskImageProps {
  imagePath: string;
}

export const TaskImage: FC<TaskImageProps> = () => {
  const { imageUrl: mediumImageUrl, isLoading: isMediumLoading } = useSupabaseImage('task_image_medium.webp');
  const { imageUrl: smallImageUrl, isLoading: isSmallLoading } = useSupabaseImage('task_image_small.webp');

  console.log('Task image URLs:', { mediumImageUrl, smallImageUrl });

  if (isMediumLoading || isSmallLoading) {
    return <Skeleton className="w-full h-48 sm:h-32 rounded-xl" />;
  }

  return (
    <div className="w-full sm:w-24 h-48 sm:h-32 flex-shrink-0 overflow-hidden rounded-xl">
      <picture>
        <source
          media="(min-width: 640px)"
          srcSet={mediumImageUrl}
          type="image/webp"
        />
        <img
          src={smallImageUrl}
          alt="Task reference"
          className="w-full h-full object-cover"
          loading="lazy"
          decoding="async"
          onError={(e) => {
            console.error("Error loading task image");
            e.currentTarget.src = "/placeholder.svg";
          }}
        />
      </picture>
    </div>
  );
};