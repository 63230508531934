import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

export const useSupabaseVideo = (path: string, expirySeconds: number = 3600) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      setIsLoading(true);
      console.log(`Fetching video URL for path: ${path}`);
      try {
        const { data: signedUrlData, error } = await supabase.storage
          .from('media')
          .createSignedUrl(path, expirySeconds);

        if (error) {
          console.error("Error getting signed URL:", error);
          return;
        }

        if (signedUrlData?.signedUrl) {
          console.log(`Successfully got signed URL for video: ${path}`);
          setVideoUrl(signedUrlData.signedUrl);
        } else {
          console.log("No signed URL found");
        }
      } catch (err) {
        console.error("Failed to fetch video URL:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoUrl();
  }, [path, expirySeconds]);

  return { videoUrl, isLoading };
};