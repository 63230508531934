import { TaskDemo } from "./TaskDemo";

export const TaskManagementFeature = () => {
  return (
    <div 
      id="manage-tasks" 
      className="flex flex-col md:flex-row items-start justify-between gap-8 sm:gap-16 scroll-mt-24 sm:scroll-mt-32"
    >
      <div className="w-full md:w-2/5">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-left">
          Turn Images into Actionable Goals with Streamlined Task Management
        </h2>
        <p className="text-sm sm:text-base text-gray-600 text-left mb-3 sm:mb-4">
          Use your site photos to create and track tasks that keep your team focused and accountable. Attach multiple images, set deadlines, and assign tasks—all in one place.
        </p>
        <p className="text-sm sm:text-base text-gray-600 text-left">
          With Property Vue, you can bridge the gap between office and field teams, helping everyone stay on track and meet project deadlines more effectively.
        </p>
      </div>
      <div className="w-full md:w-3/5">
        <TaskDemo />
      </div>
    </div>
  );
};