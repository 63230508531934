import { Link } from "react-router-dom";
import { useSupabaseImage } from "@/hooks/useSupabaseImage";
import { Skeleton } from "@/components/ui/skeleton";

const Logo = () => {
  const { imageUrl: mediumLogoUrl, isLoading: isMediumLoading } = useSupabaseImage('medium_logo.webp');
  const { imageUrl: smallLogoUrl, isLoading: isSmallLoading } = useSupabaseImage('logo_small.webp');

  console.log('Logo URLs:', { mediumLogoUrl, smallLogoUrl });

  if (isMediumLoading || isSmallLoading) {
    return <Skeleton className="h-[51px] w-40" />;
  }

  return (
    <div className="flex-shrink-0 w-40">
      <Link to="/" className="block">
        <picture>
          <source
            media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" 
            srcSet={mediumLogoUrl}
            width="160"
            height="51"
          />
          <img
            src={smallLogoUrl}
            alt="Property Vue Logo"
            className="h-[51px] w-auto object-contain"
            width={80}
            height={51}
            loading="eager"
            decoding="async"
            fetchPriority="high"
            onError={(e) => {
              console.error('Error loading logo image');
              e.currentTarget.src = "/placeholder.svg";
            }}
          />
        </picture>
      </Link>
    </div>
  );
};

export default Logo;