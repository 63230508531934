import { ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DemoRequestForm } from "@/components/DemoRequestForm";
import { useNavigate } from "react-router-dom";

export const HeroContent = () => {
  const navigate = useNavigate();

  const handleStartFreeTrial = () => {
    navigate("/pricing");
  };

  return (
    <div className="text-center md:text-left space-y-4 md:space-y-6">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-primary leading-tight">
        Bring Order to the Chaos of Construction Photos
      </h1>
      <p className="text-base md:text-lg text-primary/70 max-w-xl mx-auto md:mx-0">
        Organize all your job site images into one platform, making them easy to find, share, and use.
      </p>
      <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start gap-6 sm:gap-4">
        <Button 
          size="lg" 
          onClick={handleStartFreeTrial}
          className="group relative w-full sm:w-auto px-6 md:px-8 py-3 rounded-xl 
            bg-gradient-to-r from-[#9b87f5] via-[#8B5CF6] to-[#7E69AB] 
            text-white border-none flex items-center justify-center min-h-[48px]
            transition-all duration-300 ease-in-out
            hover:scale-[1.02] hover:shadow-lg
            overflow-hidden"
        >
          <span className="relative z-10">
            Start Free Trial
            <ChevronRight className="ml-2 inline-block" size={20} />
          </span>
          <div className="absolute inset-0 animate-button-shimmer bg-gradient-to-r from-transparent via-white/30 to-transparent" />
        </Button>
        <div className="w-full sm:w-auto">
          <DemoRequestForm />
        </div>
      </div>
    </div>
  );
};