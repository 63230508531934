import { useQuery } from "@tanstack/react-query";

export interface PricingTierType {
  id: string;
  name: 'Starter' | 'Teams' | 'Scale';
  price: number;
  description: string | null;
  features: { name: string; included: boolean; }[];
  stripe_price_id: string;
}

const mockPricingTiers: PricingTierType[] = [
  {
    id: "1",
    name: "Starter",
    price: 11.99,
    description: "Perfect for small teams just getting started",
    features: [
      { name: "Single user", included: true },
      { name: "Access to core features", included: true },
      { name: "Cloud integrations", included: false }
    ],
    stripe_price_id: "price_1QjoXhIBHxB4TxZJWzZzfXSj"
  },
  {
    id: "2",
    name: "Teams",
    price: 29.99,
    description: "Great for growing construction teams",
    features: [
      { name: "Up to 3 users", included: true },
      { name: "Access to core features", included: true },
      { name: "Cloud integrations", included: false }
    ],
    stripe_price_id: "price_1Qi7hkIBHxB4TxZJKTOhqoyr"
  },
  {
    id: "3",
    name: "Scale",
    price: 49.99,
    description: "For large enterprises needing full features",
    features: [
      { name: "Unlimited users", included: true },
      { name: "Access to core features", included: true },
      { name: "Cloud integrations", included: true }
    ],
    stripe_price_id: "price_1Qi7i0IBHxB4TxZJpi6GJC8E"
  }
];

export const usePricingTiers = () => {
  return useQuery({
    queryKey: ['pricing-tiers'],
    queryFn: async () => mockPricingTiers,
  });
};