import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

interface StepCardProps {
  step: {
    number: string;
    title: string;
    description: string;
  };
  index: number;
}

export const StepCard = ({ step }: StepCardProps) => {
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
      }}
      className="relative h-full group"
    >
      {/* Main card container with frosted glass effect */}
      <div 
        className={cn(
          "relative h-full p-8 rounded-2xl transition-all duration-500 overflow-hidden",
          // Base layer - removed the white background to fix double border effect
          "backdrop-blur-2xl",
          // Border and shadow effects
          "border border-white/20",
          "shadow-[0_8px_32px_rgba(13,51,102,0.1)]",
          // Hover effects
          "group-hover:shadow-lg group-hover:border-white/30",
          "group-hover:bg-white/[0.03]",
          // Ensure proper stacking
          "isolate"
        )}
      >
        {/* Noise texture overlay */}
        <div 
          className="absolute inset-0 opacity-[0.03] mix-blend-overlay pointer-events-none"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)'/%3E%3C/svg%3E")`,
            transform: 'translate3d(0, 0, 0)',
          }}
        />

        {/* Inner light reflection */}
        <div 
          className={cn(
            "absolute inset-0 pointer-events-none",
            "bg-gradient-to-br from-white/[0.07] via-transparent to-primary/[0.05]",
            "group-hover:opacity-75 transition-opacity duration-500"
          )}
        />

        {/* Edge lighting effect */}
        <div 
          className={cn(
            "absolute inset-0 pointer-events-none rounded-2xl",
            "bg-gradient-to-tr from-transparent via-white/[0.05] to-transparent",
            "opacity-50 group-hover:opacity-75 transition-opacity duration-500"
          )}
        />

        {/* Content container with proper z-index */}
        <div className="relative z-[2] flex flex-col gap-4">
          {/* Number badge with frosted effect */}
          <div 
            className={cn(
              "w-12 h-12 rounded-full flex items-center justify-center",
              "bg-white/10 backdrop-blur-xl",
              "border border-white/20",
              "shadow-sm",
              "text-xl font-bold text-primary",
              "transition-all duration-500",
              "group-hover:bg-primary group-hover:text-white",
              "group-hover:border-primary/20 group-hover:shadow-md"
            )}
          >
            {step.number}
          </div>

          {/* Text content */}
          <div className="space-y-2">
            <h3 className="text-xl font-semibold text-primary">
              {step.title}
            </h3>
            <p className="text-primary/70">
              {step.description}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};